import React from 'react'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Container from 'Primitive/Container'
import Type from 'Primitive/Type'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout shouldHaveSpaceForNav disableFooterOverlay>
      <SEO title={t('404.seo.title')} slug={'/404'} />
      <Container size="wide" center gutter spacious as="section">
        <div style={{ marginTop: '80px' }} />
        <Type as="h1" size="displayLarge">
          {t('404.title')}
        </Type>
        <Type as="p" size="baseLarge">
          {t('404.subtitle')}
        </Type>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
